// src/components/Shared/LoadingSpinner.js
import React from 'react';
import { Spinner, Flex } from '@chakra-ui/react';

const LoadingSpinner = () => (
  <Flex align="center" justify="center" height="100%">
    <Spinner size="xl" />
  </Flex>
);

export default LoadingSpinner;
