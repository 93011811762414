// src/App.js

import React, { useState, useEffect, Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import {
  ChakraProvider,
  Box,
  Flex,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Dock from "./components/Shared/Dock";
import Footer from "./components/Shared/Footer";
import { checkTokenExpiration } from "./store/actions/authActions";
import ProtectedRoute from "./components/ProtectedRoute"; // Separate component for ProtectedRoute
import LoadingSpinner from "./components/Shared/LoadingSpinner"; // Fallback loading component

// Lazy loaded components for better performance
const Login = lazy(() => import("./components/Auth/Login"));
const Register = lazy(() => import("./components/Auth/Register"));
const Conversation = lazy(() => import("./components/Conversation"));
const KnowledgeBase = lazy(() =>
  import("./components/Collection/KnowledgeBase")
);
const KnowledgeBaseDetails = lazy(() =>
  import("./components/Collection/KnowledgeBaseDetails")
);
const Bots = lazy(() => import("./components/Bot/Bots"));
const AuthCallback = lazy(() => import("./components/Auth/AuthCallback"));
const BotEmbedConversation = lazy(() => import("./components/BotEmbed"));
const FlowEditor = lazy(() =>
  import("./components/FlowChart/FlowEditor/FlowEditor")
);
const QuizDashboard = lazy(() => import("./components/Quiz/QuizDashboard"));
const QuizComponent = lazy(() => import("./components/Quiz/QuizComponent"));
const TakeQuizComponent = lazy(() =>
  import("./components/Quiz/TakeQuizComponent")
);
const DashboardLayout = lazy(() => import("./components/Quiz/DashboardLayout"));

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const location = useLocation();

  // Determine if the current screen is mobile
  const isMobile = useBreakpointValue({ base: true, md: false });

  // Determine if the current route is the Login page
  const isLoginPage = location.pathname === "/login";

  useEffect(() => {
    dispatch(checkTokenExpiration());
  }, [dispatch]);

  const toggleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <ChakraProvider>
      {/* Flex container to structure Dock, Main Content, and Footer */}
      <Flex direction="column" minHeight="100vh">
        {/* Header: Dock (fixed) - Render only if user is logged in */}
        {token && <Dock />}

        {/* Main Content Area */}
        <Box
          flex="1"
          // Add top padding if Dock is rendered
          pt={token ? "16" : "0"} // 4rem (assuming Dock's height is 4rem)
          // Add bottom padding to account for the fixed Footer height
          pb={token ? "10" : "0"} // 2.5rem (assuming Footer's height is 2.5rem)
          overflowY={isLoginPage ? "hidden" : "auto"} // Disable scroll on Login page
          bg="#232121"
          color="white"
          width="100%"
          // Responsive padding: no padding on Login page, padding on other pages
          // p={isLoginPage ? 0 : { base: 4, md: 6 }}
        >
          <Flex
            direction="column"
            maxW={"100%"} // Full width for Login page
            mx={isLoginPage ? 0 : "auto"} // No horizontal margin for Login page
            width="100%"
            // px={isLoginPage ? 0 : { base: 2, md: 4 }} // Remove horizontal padding for Login page
            height={isLoginPage ? "100%" : "auto"} // Full height for Login page
            justifyContent={isLoginPage ? "center" : "initial"} // Center vertically for Login page
            alignItems={isLoginPage ? "center" : "initial"} // Center horizontally for Login page
          >
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                {/* Bot Embed Route */}
                <Route
                  path="/embed/conversation"
                  element={<BotEmbedConversation />}
                />

                {/* Public Routes */}
                <Route
                  path="/login"
                  element={
                    token ? <Navigate to="/conversation" replace /> : <Login />
                  }
                />

                {/* Protected Routes */}
                <Route
                  path="/register"
                  element={
                    <ProtectedRoute>
                      <Register />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/conversation"
                  element={
                    <ProtectedRoute>
                      <Conversation collapsed={collapsed} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/knowledgebase"
                  element={
                    <ProtectedRoute>
                      <KnowledgeBase />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/knowledgebase/details/:collectionId"
                  element={
                    <ProtectedRoute>
                      <KnowledgeBaseDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/graph"
                  element={
                    <ProtectedRoute>
                      <Box>Graph</Box>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/file-management"
                  element={
                    <ProtectedRoute>
                      <Box>File Management</Box>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Box>Profile</Box>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/edit-profile"
                  element={
                    <ProtectedRoute>
                      <Box>Edit Profile</Box>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/bots"
                  element={
                    <ProtectedRoute>
                      <Bots />
                    </ProtectedRoute>
                  }
                />
                <Route path="/auth/callback" element={<AuthCallback />} />
                <Route
                  path="/flowchart"
                  element={
                    <ProtectedRoute>
                      <FlowEditor />
                    </ProtectedRoute>
                  }
                />

                {/* Quiz Routes */}
                <Route
                  path="/quiz-dashboard"
                  element={
                    <ProtectedRoute>
                      <DashboardLayout>
                        <QuizDashboard />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/quiz"
                  element={
                    <ProtectedRoute>
                      <DashboardLayout>
                        <QuizComponent />
                      </DashboardLayout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/quiz/take/:quizId"
                  element={
                    <ProtectedRoute>
                      <TakeQuizComponent />
                    </ProtectedRoute>
                  }
                />

                {/* Root Route */}
                <Route
                  path="/"
                  element={
                    <Navigate to={token ? "/conversation" : "/login"} replace />
                  }
                />

                {/* 404 Not Found */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </Flex>
        </Box>

        {/* Footer: Fixed at Bottom - Render only if user is logged in */}
        {token && <Footer />}
      </Flex>
    </ChakraProvider>
  );
};

export default App;
