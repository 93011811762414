// src/components/Shared/Dock.jsx

import React from "react";
import {
  Box,
  Flex,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ChatIcon, AtSignIcon } from "@chakra-ui/icons";
import { FaRobot, FaProjectDiagram, FaBook } from "react-icons/fa"; // Import FaBook for Quiz
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode"; // Corrected import
import { logoutUser } from "../../store/actions/authActions";
import degLogo from "../../assets/images/deg.png";
import degname from "../../assets/images/degname.png";

const Dock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  let user = null;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      // Extract the necessary fields from the decoded token
      user = {
        loginId: decodedToken.LoginId,
        userName: decodedToken.UserName,
        fullName: decodedToken.FullName,
        userType: decodedToken.UserType,
      };
    } catch (error) {
      console.error("Invalid token:", error);
    }
  }

  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("selectedConversation"); // Clear the selected conversation on logout
    navigate("/login");
  };

  return (
    <Box
      bg="#2e2e2e"
      color="white"
      px={4}
      h={16} // Fixed height (4rem)
      position="fixed"
      top="0"
      width="100%"
      zIndex="1000"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <HStack spacing={4}>
        <img src={degLogo} alt="Domain Experts Logo" width="30" />
        <Box as="span" ml={2} fontWeight="bold" fontSize="lg">
          <img
            src={degname}
            alt="Domain Experts AI"
            style={{ width: "12rem" }}
          />
        </Box>
      </HStack>
      <HStack spacing={4}>
        {/* Knowledge Base Button */}
        <Button
          leftIcon={<AtSignIcon />}
          variant="ghost"
          colorScheme="whiteAlpha"
          onClick={() => navigate("/knowledgebase")}
          bg={
            location.pathname === "/knowledgebase"
              ? "whiteAlpha.300"
              : "transparent"
          }
          _hover={{ bg: "gray.800" }}
          color="white"
        >
          Knowledge Base
        </Button>
        {/* Chat Button */}
        <Button
          leftIcon={<ChatIcon />}
          variant="ghost"
          colorScheme="whiteAlpha"
          onClick={() => navigate("/conversation")}
          bg={
            location.pathname === "/conversation"
              ? "whiteAlpha.300"
              : "transparent"
          }
          _hover={{ bg: "gray.800" }}
          color="white"
        >
          Chat
        </Button>
        {/* Bots Button */}
        <Button
          leftIcon={<FaRobot />} // Use the robot icon for the Bots button
          variant="ghost"
          colorScheme="whiteAlpha"
          onClick={() => navigate("/bots")}
          bg={
            location.pathname === "/bots" ? "whiteAlpha.300" : "transparent"
          }
          _hover={{ bg: "gray.800" }}
          color="white"
        >
          Bots
        </Button>
        {/* FlowChart Button */}
        <Button
          leftIcon={<FaProjectDiagram />} // Use the FlowChart icon
          variant="ghost"
          colorScheme="whiteAlpha"
          onClick={() => navigate("/flowchart")} // Navigate to the FlowChart route
          bg={
            location.pathname === "/flowchart"
              ? "whiteAlpha.300"
              : "transparent"
          }
          _hover={{ bg: "gray.800" }}
          color="white"
        >
          FlowChart
        </Button>
        {/* Quiz Button */}
        <Button
          leftIcon={<FaBook />} // Use the book icon for Quiz
          variant="ghost"
          colorScheme="whiteAlpha"
          onClick={() => navigate("/quiz-dashboard")} // Navigate to the Quiz Dashboard route
          bg={
            location.pathname === "/quiz-dashboard"
              ? "whiteAlpha.300"
              : "transparent"
          }
          _hover={{ bg: "gray.800" }}
          color="white"
        >
          Quiz
        </Button>
      </HStack>
      {/* User Avatar Menu */}
      <Menu>
        <MenuButton as={Button} variant="link" cursor="pointer" minW={0}>
          <Avatar name={user?.fullName || ""} size="sm" />
        </MenuButton>
        <MenuList bg="#232121" borderColor="gray.700">
          <MenuItem
            bg="rgb(0 0 0 / 17%)"
            _hover={{ bg: "gray.800" }}
            onClick={handleLogout}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Dock;
