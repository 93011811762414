// src/store/actions/quizActions.js

import {
  FETCH_ALL_QUIZZES_REQUEST,
  FETCH_ALL_QUIZZES_SUCCESS,
  FETCH_ALL_QUIZZES_FAILURE,
  FETCH_QUIZ_DETAILS_REQUEST,
  FETCH_QUIZ_DETAILS_SUCCESS,
  FETCH_QUIZ_DETAILS_FAILURE,
  SUBMIT_QUIZ_ANSWERS_REQUEST,
  SUBMIT_QUIZ_ANSWERS_SUCCESS,
  SUBMIT_QUIZ_ANSWERS_FAILURE,
  FETCH_QUIZ_RESULTS_REQUEST,
  FETCH_QUIZ_RESULTS_SUCCESS,
  FETCH_QUIZ_RESULTS_FAILURE,
  FETCH_PERFORMANCE_DATA_REQUEST,
  FETCH_PERFORMANCE_DATA_SUCCESS,
  FETCH_PERFORMANCE_DATA_FAILURE,
  FETCH_DASHBOARD_SUMMARY_REQUEST,
  FETCH_DASHBOARD_SUMMARY_SUCCESS,
  FETCH_DASHBOARD_SUMMARY_FAILURE,
  FETCH_PROGRESS_DATA_REQUEST,
  FETCH_PROGRESS_DATA_SUCCESS,
  FETCH_PROGRESS_DATA_FAILURE,
  FETCH_RECOMMENDATIONS_REQUEST,
  FETCH_RECOMMENDATIONS_SUCCESS,
  FETCH_RECOMMENDATIONS_FAILURE,
  DELETE_QUIZ_REQUEST,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILURE,
  UPDATE_QUIZ_REQUEST,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAILURE,
  GET_QUIZ_ATTEMPTS_REQUEST,
  GET_QUIZ_ATTEMPTS_SUCCESS,
  GET_QUIZ_ATTEMPTS_FAILURE,
  DELETE_QUIZ_ATTEMPT_REQUEST,
  DELETE_QUIZ_ATTEMPT_SUCCESS,
  DELETE_QUIZ_ATTEMPT_FAILURE,
  GET_QUIZZES_BY_CATEGORY_REQUEST,
  GET_QUIZZES_BY_CATEGORY_SUCCESS,
  GET_QUIZZES_BY_CATEGORY_FAILURE,
  GET_QUIZ_CATEGORIES_REQUEST,
  GET_QUIZ_CATEGORIES_SUCCESS,
  GET_QUIZ_CATEGORIES_FAILURE,
} from "../types/quizTypes";

// Fetch All Quizzes
export const fetchAllQuizzesRequest = () => ({
  type: FETCH_ALL_QUIZZES_REQUEST,
});

export const fetchAllQuizzesSuccess = (quizzes) => ({
  type: FETCH_ALL_QUIZZES_SUCCESS,
  payload: quizzes,
});

export const fetchAllQuizzesFailure = (error) => ({
  type: FETCH_ALL_QUIZZES_FAILURE,
  payload: error,
});

// Delete Quiz
export const deleteQuiz = (quizId) => ({
  type: DELETE_QUIZ_REQUEST,
  payload: quizId,
});

export const deleteQuizSuccess = (message) => ({
  type: DELETE_QUIZ_SUCCESS,
  payload: message,
});

export const deleteQuizFailure = (error) => ({
  type: DELETE_QUIZ_FAILURE,
  payload: error,
});

// Update Quiz
export const updateQuiz = (quizId, data) => ({
  type: UPDATE_QUIZ_REQUEST,
  payload: { quizId, data },
});

export const updateQuizSuccess = (message) => ({
  type: UPDATE_QUIZ_SUCCESS,
  payload: message,
});

export const updateQuizFailure = (error) => ({
  type: UPDATE_QUIZ_FAILURE,
  payload: error,
});

// Get Quiz Attempts
export const getQuizAttempts = (quizId) => ({
  type: GET_QUIZ_ATTEMPTS_REQUEST,
  payload: quizId,
});

export const getQuizAttemptsSuccess = (attempts) => ({
  type: GET_QUIZ_ATTEMPTS_SUCCESS,
  payload: attempts,
});

export const getQuizAttemptsFailure = (error) => ({
  type: GET_QUIZ_ATTEMPTS_FAILURE,
  payload: error,
});

// Delete Quiz Attempt
export const deleteQuizAttempt = (quizId, attemptId) => ({
  type: DELETE_QUIZ_ATTEMPT_REQUEST,
  payload: { quizId, attemptId },
});

export const deleteQuizAttemptSuccess = (message) => ({
  type: DELETE_QUIZ_ATTEMPT_SUCCESS,
  payload: message,
});

export const deleteQuizAttemptFailure = (error) => ({
  type: DELETE_QUIZ_ATTEMPT_FAILURE,
  payload: error,
});

// Get Quizzes by Category
export const getQuizzesByCategory = () => ({
  type: GET_QUIZZES_BY_CATEGORY_REQUEST,
});

export const getQuizzesByCategorySuccess = (quizzesByCategory) => ({
  type: GET_QUIZZES_BY_CATEGORY_SUCCESS,
  payload: quizzesByCategory,
});

export const getQuizzesByCategoryFailure = (error) => ({
  type: GET_QUIZZES_BY_CATEGORY_FAILURE,
  payload: error,
});

// Get Quiz Categories
export const getQuizCategories = () => ({
  type: GET_QUIZ_CATEGORIES_REQUEST,
});

export const getQuizCategoriesSuccess = (categories) => ({
  type: GET_QUIZ_CATEGORIES_SUCCESS,
  payload: categories,
});

export const getQuizCategoriesFailure = (error) => ({
  type: GET_QUIZ_CATEGORIES_FAILURE,
  payload: error,
});

// Existing action creators for fetching quiz details, submitting answers, etc.
export const fetchQuizDetailsRequest = (quizId) => ({
  type: FETCH_QUIZ_DETAILS_REQUEST,
  payload: quizId,
});

export const fetchQuizDetailsSuccess = (quiz) => ({
  type: FETCH_QUIZ_DETAILS_SUCCESS,
  payload: quiz,
});

export const fetchQuizDetailsFailure = (error) => ({
  type: FETCH_QUIZ_DETAILS_FAILURE,
  payload: error,
});

export const submitQuizAnswersRequest = (quizId, answers) => ({
  type: SUBMIT_QUIZ_ANSWERS_REQUEST,
  payload: { quizId, answers },
});

export const submitQuizAnswersSuccess = (result) => ({
  type: SUBMIT_QUIZ_ANSWERS_SUCCESS,
  payload: result,
});

export const submitQuizAnswersFailure = (error) => ({
  type: SUBMIT_QUIZ_ANSWERS_FAILURE,
  payload: error,
});

// Other existing action creators remain the same...
  
  // Fetch Quiz Results
  export const fetchQuizResultsRequest = () => ({
    type: FETCH_QUIZ_RESULTS_REQUEST,
  });
  
  export const fetchQuizResultsSuccess = (results) => ({
    type: FETCH_QUIZ_RESULTS_SUCCESS,
    payload: results,
  });
  
  export const fetchQuizResultsFailure = (error) => ({
    type: FETCH_QUIZ_RESULTS_FAILURE,
    payload: error,
  });
  
  // Fetch Performance Data
  export const fetchPerformanceDataRequest = () => ({
    type: FETCH_PERFORMANCE_DATA_REQUEST,
  });
  
  export const fetchPerformanceDataSuccess = (performance) => ({
    type: FETCH_PERFORMANCE_DATA_SUCCESS,
    payload: performance,
  });
  
  export const fetchPerformanceDataFailure = (error) => ({
    type: FETCH_PERFORMANCE_DATA_FAILURE,
    payload: error,
  });
  
  // Fetch Dashboard Summary
  export const fetchDashboardSummaryRequest = () => ({
    type: FETCH_DASHBOARD_SUMMARY_REQUEST,
  });
  
  export const fetchDashboardSummarySuccess = (summary) => ({
    type: FETCH_DASHBOARD_SUMMARY_SUCCESS,
    payload: summary,
  });
  
  export const fetchDashboardSummaryFailure = (error) => ({
    type: FETCH_DASHBOARD_SUMMARY_FAILURE,
    payload: error,
  });
  
  // Fetch Progress Data
  export const fetchProgressDataRequest = () => ({
    type: FETCH_PROGRESS_DATA_REQUEST,
  });
  
  export const fetchProgressDataSuccess = (progress) => ({
    type: FETCH_PROGRESS_DATA_SUCCESS,
    payload: progress,
  });
  
  export const fetchProgressDataFailure = (error) => ({
    type: FETCH_PROGRESS_DATA_FAILURE,
    payload: error,
  });
  
  // Fetch Recommendations
  export const fetchRecommendationsRequest = () => ({
    type: FETCH_RECOMMENDATIONS_REQUEST,
  });
  
  export const fetchRecommendationsSuccess = (recommendations) => ({
    type: FETCH_RECOMMENDATIONS_SUCCESS,
    payload: recommendations,
  });
  
  export const fetchRecommendationsFailure = (error) => ({
    type: FETCH_RECOMMENDATIONS_FAILURE,
    payload: error,
  });
  