// src/store/reducers/quizReducer.js

import {
  FETCH_ALL_QUIZZES_REQUEST,
  FETCH_ALL_QUIZZES_SUCCESS,
  FETCH_ALL_QUIZZES_FAILURE,
  FETCH_QUIZ_DETAILS_REQUEST,
  FETCH_QUIZ_DETAILS_SUCCESS,
  FETCH_QUIZ_DETAILS_FAILURE,
  SUBMIT_QUIZ_ANSWERS_REQUEST,
  SUBMIT_QUIZ_ANSWERS_SUCCESS,
  SUBMIT_QUIZ_ANSWERS_FAILURE,
  FETCH_QUIZ_RESULTS_REQUEST,
  FETCH_QUIZ_RESULTS_SUCCESS,
  FETCH_QUIZ_RESULTS_FAILURE,
  FETCH_PERFORMANCE_DATA_REQUEST,
  FETCH_PERFORMANCE_DATA_SUCCESS,
  FETCH_PERFORMANCE_DATA_FAILURE,
  FETCH_DASHBOARD_SUMMARY_REQUEST,
  FETCH_DASHBOARD_SUMMARY_SUCCESS,
  FETCH_DASHBOARD_SUMMARY_FAILURE,
  FETCH_PROGRESS_DATA_REQUEST,
  FETCH_PROGRESS_DATA_SUCCESS,
  FETCH_PROGRESS_DATA_FAILURE,
  FETCH_RECOMMENDATIONS_REQUEST,
  FETCH_RECOMMENDATIONS_SUCCESS,
  FETCH_RECOMMENDATIONS_FAILURE,
  DELETE_QUIZ_REQUEST,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILURE,
  UPDATE_QUIZ_REQUEST,
  UPDATE_QUIZ_SUCCESS,
  UPDATE_QUIZ_FAILURE,
  GET_QUIZ_ATTEMPTS_REQUEST,
  GET_QUIZ_ATTEMPTS_SUCCESS,
  GET_QUIZ_ATTEMPTS_FAILURE,
  DELETE_QUIZ_ATTEMPT_REQUEST,
  DELETE_QUIZ_ATTEMPT_SUCCESS,
  DELETE_QUIZ_ATTEMPT_FAILURE,
  GET_QUIZZES_BY_CATEGORY_REQUEST,
  GET_QUIZZES_BY_CATEGORY_SUCCESS,
  GET_QUIZZES_BY_CATEGORY_FAILURE,
  GET_QUIZ_CATEGORIES_REQUEST,
  GET_QUIZ_CATEGORIES_SUCCESS,
  GET_QUIZ_CATEGORIES_FAILURE,
} from "../types/quizTypes";

const initialState = {
  quizzes: [],
  currentQuiz: null,
  results: [],
  performance: [],
  dashboardSummary: {},
  progress: [],
  recommendations: [],
  loading: false,
  error: null,
};

const quizReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch All Quizzes
    case FETCH_ALL_QUIZZES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ALL_QUIZZES_SUCCESS:
      return {
        ...state,
        loading: false,
        quizzes: action.payload,
      };
    case FETCH_ALL_QUIZZES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Fetch Quiz Details
    case FETCH_QUIZ_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_QUIZ_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        currentQuiz: action.payload,
      };
    case FETCH_QUIZ_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Submit Quiz Answers
    case SUBMIT_QUIZ_ANSWERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUBMIT_QUIZ_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        // Optionally, you can add the result to results
        results: [...state.results, action.payload],
      };
    case SUBMIT_QUIZ_ANSWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Fetch Quiz Results
    case FETCH_QUIZ_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_QUIZ_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        results: action.payload,
      };
    case FETCH_QUIZ_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Fetch Performance Data
    case FETCH_PERFORMANCE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        performance: action.payload,
      };
    case FETCH_PERFORMANCE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Fetch Dashboard Summary
    case FETCH_DASHBOARD_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardSummary: action.payload,
      };
    case FETCH_DASHBOARD_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Fetch Progress Data
    case FETCH_PROGRESS_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROGRESS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
      };
    case FETCH_PROGRESS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Fetch Recommendations
    case FETCH_RECOMMENDATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        recommendations: action.payload,
      };
    case FETCH_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    // Delete Quiz
    case DELETE_QUIZ_REQUEST:
    case UPDATE_QUIZ_REQUEST:
    case DELETE_QUIZ_ATTEMPT_REQUEST:
      return { ...state, loading: true, error: null };

    case DELETE_QUIZ_SUCCESS:
    case UPDATE_QUIZ_SUCCESS:
    case DELETE_QUIZ_ATTEMPT_SUCCESS:
      return { ...state, loading: false };

    case DELETE_QUIZ_FAILURE:
    case UPDATE_QUIZ_FAILURE:
    case DELETE_QUIZ_ATTEMPT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get Quiz Attempts
    case GET_QUIZ_ATTEMPTS_REQUEST:
      return { ...state, loading: true, error: null, quizAttempts: [] };

    case GET_QUIZ_ATTEMPTS_SUCCESS:
      return { ...state, loading: false, quizAttempts: action.payload };

    case GET_QUIZ_ATTEMPTS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get Quizzes by Category
    case GET_QUIZZES_BY_CATEGORY_REQUEST:
      return { ...state, loading: true, error: null, quizzesByCategory: [] };

    case GET_QUIZZES_BY_CATEGORY_SUCCESS:
      return { ...state, loading: false, quizzesByCategory: action.payload };

    case GET_QUIZZES_BY_CATEGORY_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get Quiz Categories
    case GET_QUIZ_CATEGORIES_REQUEST:
      return { ...state, loading: true, error: null, quizCategories: [] };

    case GET_QUIZ_CATEGORIES_SUCCESS:
      return { ...state, loading: false, quizCategories: action.payload };

    case GET_QUIZ_CATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default quizReducer;
