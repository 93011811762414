// src/store/types/quizTypes.js

// Fetch All Quizzes
export const FETCH_ALL_QUIZZES_REQUEST = "FETCH_ALL_QUIZZES_REQUEST";
export const FETCH_ALL_QUIZZES_SUCCESS = "FETCH_ALL_QUIZZES_SUCCESS";
export const FETCH_ALL_QUIZZES_FAILURE = "FETCH_ALL_QUIZZES_FAILURE";

// Fetch Quiz Details
export const FETCH_QUIZ_DETAILS_REQUEST = "FETCH_QUIZ_DETAILS_REQUEST";
export const FETCH_QUIZ_DETAILS_SUCCESS = "FETCH_QUIZ_DETAILS_SUCCESS";
export const FETCH_QUIZ_DETAILS_FAILURE = "FETCH_QUIZ_DETAILS_FAILURE";

// Submit Quiz Answers
export const SUBMIT_QUIZ_ANSWERS_REQUEST = "SUBMIT_QUIZ_ANSWERS_REQUEST";
export const SUBMIT_QUIZ_ANSWERS_SUCCESS = "SUBMIT_QUIZ_ANSWERS_SUCCESS";
export const SUBMIT_QUIZ_ANSWERS_FAILURE = "SUBMIT_QUIZ_ANSWERS_FAILURE";

// Fetch Quiz Results
export const FETCH_QUIZ_RESULTS_REQUEST = "FETCH_QUIZ_RESULTS_REQUEST";
export const FETCH_QUIZ_RESULTS_SUCCESS = "FETCH_QUIZ_RESULTS_SUCCESS";
export const FETCH_QUIZ_RESULTS_FAILURE = "FETCH_QUIZ_RESULTS_FAILURE";

// Fetch Performance Data
export const FETCH_PERFORMANCE_DATA_REQUEST = "FETCH_PERFORMANCE_DATA_REQUEST";
export const FETCH_PERFORMANCE_DATA_SUCCESS = "FETCH_PERFORMANCE_DATA_SUCCESS";
export const FETCH_PERFORMANCE_DATA_FAILURE = "FETCH_PERFORMANCE_DATA_FAILURE";

// Fetch Dashboard Summary
export const FETCH_DASHBOARD_SUMMARY_REQUEST = "FETCH_DASHBOARD_SUMMARY_REQUEST";
export const FETCH_DASHBOARD_SUMMARY_SUCCESS = "FETCH_DASHBOARD_SUMMARY_SUCCESS";
export const FETCH_DASHBOARD_SUMMARY_FAILURE = "FETCH_DASHBOARD_SUMMARY_FAILURE";

// Fetch Progress Data
export const FETCH_PROGRESS_DATA_REQUEST = "FETCH_PROGRESS_DATA_REQUEST";
export const FETCH_PROGRESS_DATA_SUCCESS = "FETCH_PROGRESS_DATA_SUCCESS";
export const FETCH_PROGRESS_DATA_FAILURE = "FETCH_PROGRESS_DATA_FAILURE";

// Fetch Recommendations
export const FETCH_RECOMMENDATIONS_REQUEST = "FETCH_RECOMMENDATIONS_REQUEST";
export const FETCH_RECOMMENDATIONS_SUCCESS = "FETCH_RECOMMENDATIONS_SUCCESS";
export const FETCH_RECOMMENDATIONS_FAILURE = "FETCH_RECOMMENDATIONS_FAILURE";

// Delete Quiz
export const DELETE_QUIZ_REQUEST = "DELETE_QUIZ_REQUEST";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAILURE = "DELETE_QUIZ_FAILURE";

// Update Quiz
export const UPDATE_QUIZ_REQUEST = "UPDATE_QUIZ_REQUEST";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAILURE = "UPDATE_QUIZ_FAILURE";

// Get Quiz Attempts
export const GET_QUIZ_ATTEMPTS_REQUEST = "GET_QUIZ_ATTEMPTS_REQUEST";
export const GET_QUIZ_ATTEMPTS_SUCCESS = "GET_QUIZ_ATTEMPTS_SUCCESS";
export const GET_QUIZ_ATTEMPTS_FAILURE = "GET_QUIZ_ATTEMPTS_FAILURE";

// Delete Quiz Attempt
export const DELETE_QUIZ_ATTEMPT_REQUEST = "DELETE_QUIZ_ATTEMPT_REQUEST";
export const DELETE_QUIZ_ATTEMPT_SUCCESS = "DELETE_QUIZ_ATTEMPT_SUCCESS";
export const DELETE_QUIZ_ATTEMPT_FAILURE = "DELETE_QUIZ_ATTEMPT_FAILURE";

// Get Quizzes by Category
export const GET_QUIZZES_BY_CATEGORY_REQUEST = "GET_QUIZZES_BY_CATEGORY_REQUEST";
export const GET_QUIZZES_BY_CATEGORY_SUCCESS = "GET_QUIZZES_BY_CATEGORY_SUCCESS";
export const GET_QUIZZES_BY_CATEGORY_FAILURE = "GET_QUIZZES_BY_CATEGORY_FAILURE";

// Get Quiz Categories
export const GET_QUIZ_CATEGORIES_REQUEST = "GET_QUIZ_CATEGORIES_REQUEST";
export const GET_QUIZ_CATEGORIES_SUCCESS = "GET_QUIZ_CATEGORIES_SUCCESS";
export const GET_QUIZ_CATEGORIES_FAILURE = "GET_QUIZ_CATEGORIES_FAILURE";
