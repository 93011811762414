// src/components/Shared/Footer.jsx

import React from 'react';
import { Box, Text, Link, HStack, Icon } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';

const Footer = () => {
  return (
    <Box
      as="footer"
      bg="#2e2e2e"
      color="white"
      textAlign="center"
      py={2}
      width="100%"
      position="fixed"
      bottom="0"
      h={10} // Fixed height (2.5rem)
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      zIndex="1000" // Ensure it stays above other elements if needed
    >
      <Text fontSize="sm">© {new Date().getFullYear()} DomainExpertsGroup LC</Text>
      <HStack justify="center" spacing={2} fontSize="sm" mt={1}>
        <EmailIcon />
        <Link href="mailto:contact@domainexpertsgroup.com" color="red.500">
          contact@domainexpertsgroup.com
        </Link>
        <PhoneIcon />
        <Link href="tel:+1 8323355362" color="red.500">
          +1 678-TALK-DEG
        </Link>
      </HStack>
    </Box>
  );
};

export default Footer;
