// src/store/sagas/quizSagas.js

import { takeLatest, call, put, all } from "redux-saga/effects";
import {
  FETCH_ALL_QUIZZES_REQUEST,
  FETCH_QUIZ_RESULTS_REQUEST,
  FETCH_QUIZ_DETAILS_REQUEST,
  SUBMIT_QUIZ_ANSWERS_REQUEST,
  FETCH_PERFORMANCE_DATA_REQUEST,
  FETCH_DASHBOARD_SUMMARY_REQUEST,
  FETCH_PROGRESS_DATA_REQUEST,
  FETCH_RECOMMENDATIONS_REQUEST,
  DELETE_QUIZ_REQUEST,
  UPDATE_QUIZ_REQUEST,
  GET_QUIZ_ATTEMPTS_REQUEST,
  DELETE_QUIZ_ATTEMPT_REQUEST,
  GET_QUIZZES_BY_CATEGORY_REQUEST,
  GET_QUIZ_CATEGORIES_REQUEST,
} from "../types/quizTypes";

import {
  fetchAllQuizzesSuccess,
  fetchAllQuizzesFailure,
  fetchQuizResultsSuccess,
  fetchQuizResultsFailure,
  fetchQuizDetailsSuccess,
  fetchQuizDetailsFailure,
  submitQuizAnswersSuccess,
  submitQuizAnswersFailure,
  fetchPerformanceDataSuccess,
  fetchPerformanceDataFailure,
  fetchDashboardSummarySuccess,
  fetchDashboardSummaryFailure,
  fetchProgressDataSuccess,
  fetchProgressDataFailure,
  fetchRecommendationsSuccess,
  fetchRecommendationsFailure,
  deleteQuizSuccess,
  deleteQuizFailure,
  updateQuizSuccess,
  updateQuizFailure,
  getQuizAttemptsSuccess,
  getQuizAttemptsFailure,
  deleteQuizAttemptSuccess,
  deleteQuizAttemptFailure,
  getQuizzesByCategorySuccess,
  getQuizzesByCategoryFailure,
  getQuizCategoriesSuccess,
  getQuizCategoriesFailure,
  fetchAllQuizzesRequest, // For refetching quizzes
} from "../actions/quizActions";

import {
  getAllQuizzes,
  fetchQuizDetails,
  submitQuizAnswers,
  getQuizResults,
  getPerformanceData,
  getDashboardSummary,
  getProgressData,
  getRecommendations,
  deleteQuiz,
  updateQuiz,
  getQuizAttempts,
  deleteQuizAttempt,
  getQuizzesByCategory,
  getQuizCategories,
} from "../../apiService";
// Saga: Fetch All Quizzes
function* fetchAllQuizzesSaga() {
  try {
    const response = yield call(getAllQuizzes);
    yield put(fetchAllQuizzesSuccess(response.data.quizzes));
  } catch (error) {
    yield put(fetchAllQuizzesFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Fetch Quiz Details
function* fetchQuizDetailsSaga(action) {
  try {
    const { payload: quizId } = action;
    const response = yield call(fetchQuizDetails, quizId);
    yield put(fetchQuizDetailsSuccess(response.data.quiz));
  } catch (error) {
    yield put(fetchQuizDetailsFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Submit Quiz Answers
function* submitQuizAnswersSaga(action) {
  try {
    const { quizId, answers } = action.payload;
    const response = yield call(submitQuizAnswers, quizId, answers);
    yield put(submitQuizAnswersSuccess(response.data));
    // Optionally, refetch quiz results or dashboard summary after submission
  } catch (error) {
    yield put(submitQuizAnswersFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Fetch Quiz Results
function* fetchQuizResultsSaga() {
  try {
    const response = yield call(getQuizResults);
    yield put(fetchQuizResultsSuccess(response.data.results));
  } catch (error) {
    yield put(fetchQuizResultsFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Fetch Performance Data
function* fetchPerformanceDataSaga() {
  try {
    const response = yield call(getPerformanceData);
    yield put(fetchPerformanceDataSuccess(response.data.performance));
  } catch (error) {
    yield put(fetchPerformanceDataFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Fetch Dashboard Summary
function* fetchDashboardSummarySaga() {
  try {
    const response = yield call(getDashboardSummary);
    yield put(fetchDashboardSummarySuccess(response.data));
  } catch (error) {
    yield put(fetchDashboardSummaryFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Fetch Progress Data
function* fetchProgressDataSaga() {
  try {
    const response = yield call(getProgressData);
    yield put(fetchProgressDataSuccess(response.data.progress));
  } catch (error) {
    yield put(fetchProgressDataFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Fetch Recommendations
function* fetchRecommendationsSaga() {
  try {
    const response = yield call(getRecommendations);
    yield put(fetchRecommendationsSuccess(response.data.recommendations));
  } catch (error) {
    yield put(fetchRecommendationsFailure(error.response?.data?.error || error.message));
  }
}

// Watcher Sagas
function* watchFetchAllQuizzes() {
  yield takeLatest(FETCH_ALL_QUIZZES_REQUEST, fetchAllQuizzesSaga);
}

function* watchFetchQuizDetails() {
  yield takeLatest(FETCH_QUIZ_DETAILS_REQUEST, fetchQuizDetailsSaga);
}

function* watchSubmitQuizAnswers() {
  yield takeLatest(SUBMIT_QUIZ_ANSWERS_REQUEST, submitQuizAnswersSaga);
}

function* watchFetchQuizResults() {
  yield takeLatest(FETCH_QUIZ_RESULTS_REQUEST, fetchQuizResultsSaga);
}

function* watchFetchPerformanceData() {
  yield takeLatest(FETCH_PERFORMANCE_DATA_REQUEST, fetchPerformanceDataSaga);
}

function* watchFetchDashboardSummary() {
  yield takeLatest(FETCH_DASHBOARD_SUMMARY_REQUEST, fetchDashboardSummarySaga);
}

function* watchFetchProgressData() {
  yield takeLatest(FETCH_PROGRESS_DATA_REQUEST, fetchProgressDataSaga);
}

function* watchFetchRecommendations() {
  yield takeLatest(FETCH_RECOMMENDATIONS_REQUEST, fetchRecommendationsSaga);
}
// Saga: Delete Quiz
function* deleteQuizSaga(action) {
  try {
    const { payload: quizId } = action;
    const response = yield call(deleteQuiz, quizId);
    yield put(deleteQuizSuccess(response.data));
    yield put(fetchAllQuizzesRequest()); // Refetch quizzes
  } catch (error) {
    yield put(deleteQuizFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Update Quiz
function* updateQuizSaga(action) {
  try {
    const { quizId, data } = action.payload;
    const response = yield call(updateQuiz, quizId, data);
    yield put(updateQuizSuccess(response.data));
    yield put(fetchAllQuizzesRequest()); // Refetch quizzes
  } catch (error) {
    yield put(updateQuizFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Get Quiz Attempts
function* getQuizAttemptsSaga(action) {
  try {
    const { payload: quizId } = action;
    const response = yield call(getQuizAttempts, quizId);
    yield put(getQuizAttemptsSuccess(response.data.attempts));
  } catch (error) {
    yield put(getQuizAttemptsFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Delete Quiz Attempt
function* deleteQuizAttemptSaga(action) {
  try {
    const { quizId, attemptId } = action.payload;
    const response = yield call(deleteQuizAttempt, quizId, attemptId);
    yield put(deleteQuizAttemptSuccess(response.data));
    // Refetch attempts
    yield put({ type: GET_QUIZ_ATTEMPTS_REQUEST, payload: quizId });
  } catch (error) {
    yield put(deleteQuizAttemptFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Get Quizzes by Category
function* getQuizzesByCategorySaga() {
  try {
    const response = yield call(getQuizzesByCategory);
    yield put(getQuizzesByCategorySuccess(response.data.quizzes_by_category));
  } catch (error) {
    yield put(getQuizzesByCategoryFailure(error.response?.data?.error || error.message));
  }
}

// Saga: Get Quiz Categories
function* getQuizCategoriesSaga() {
  try {
    const response = yield call(getQuizCategories);
    yield put(getQuizCategoriesSuccess(response.data.categories));
  } catch (error) {
    yield put(getQuizCategoriesFailure(error.response?.data?.error || error.message));
  }
}

// Watcher Sagas
function* watchDeleteQuiz() {
  yield takeLatest(DELETE_QUIZ_REQUEST, deleteQuizSaga);
}

function* watchUpdateQuiz() {
  yield takeLatest(UPDATE_QUIZ_REQUEST, updateQuizSaga);
}

function* watchGetQuizAttempts() {
  yield takeLatest(GET_QUIZ_ATTEMPTS_REQUEST, getQuizAttemptsSaga);
}

function* watchDeleteQuizAttempt() {
  yield takeLatest(DELETE_QUIZ_ATTEMPT_REQUEST, deleteQuizAttemptSaga);
}

function* watchGetQuizzesByCategory() {
  yield takeLatest(GET_QUIZZES_BY_CATEGORY_REQUEST, getQuizzesByCategorySaga);
}

function* watchGetQuizCategories() {
  yield takeLatest(GET_QUIZ_CATEGORIES_REQUEST, getQuizCategoriesSaga);
}

// Root Quiz Saga
export default function* quizSagas() {
  yield all([
    watchFetchAllQuizzes(),
    watchFetchQuizDetails(),
    watchSubmitQuizAnswers(),
    watchFetchQuizResults(),
    watchFetchPerformanceData(),
    watchFetchDashboardSummary(),
    watchFetchProgressData(),
    watchFetchRecommendations(),
    watchDeleteQuiz(),
    watchUpdateQuiz(),
    watchGetQuizAttempts(),
    watchDeleteQuizAttempt(),
    watchGetQuizzesByCategory(),
    watchGetQuizCategories(),
  ]);
}
