import { all } from 'redux-saga/effects';
import watchLoginUser from './authSagas';
import quizSagas from "./quizSagas";


function* rootSaga() {
  yield all([watchLoginUser(),quizSagas(),]);
}

export default rootSaga;
