// src/store/reducers/index.js

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import { collectionReducer } from './collectionReducer';
import quizReducer from './quizReducer'; // Import the quizReducer

const rootReducer = combineReducers({
  auth: authReducer,
  collection: collectionReducer,
  quiz: quizReducer, // Add the quizReducer
});

export default rootReducer;
