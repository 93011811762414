// srcService.js

import axios from "axios";
import store from "./store/store"; // Import the Redux store if needed

// Set API base URL from environment variable or fallback to a default value
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to include the JWT Authorization token
api.interceptors.request.use(
  (config) => {
    // Retrieve JWT token from localStorage
    const jwtToken = localStorage.getItem("token");

    // Determine if the request is bot-specific
    const isBotEndpoint =
      config.url.includes("/bot/search_with_bot_token") ||
      config.url.includes("/bot_management/fetch_bot_details_by_token") ||
      config.url.includes("/bot_management/get_bot_conversation") ||
      config.url.includes("/bot_management/get_all_conversations"); // Added endpoint

    if (isBotEndpoint) {
      // Bot-specific endpoints: expect both bot token and JWT token
      // Bot token should be sent in the Authorization header by individual functions
      if (jwtToken) {
        config.headers["JWT-Authorization"] = `Bearer ${jwtToken}`;
      }
    } else {
      // Non-bot endpoints: only include JWT token if available
      if (jwtToken) {
        config.headers["JWT-Authorization"] = `Bearer ${jwtToken}`;
      }

      // Also include the standard Authorization header if token from Redux store exists
      const token = store.getState().auth.token;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);
// Helper functions for content type headers
const jsonContentType = { "Content-Type": "application/json" };
const formDataContentType = { "Content-Type": "multipart/form-data" };

// Authentication APIs
export const login = (credentials) => api.post("/auth/login", credentials);
export const register = (data) => api.post("/auth/register", data);
export const microsoftLogin = () => api.get("/auth/login/microsoft");
export const authCallback = (params) => api.get("/auth/callback", { params });

// Collections APIs
export const getPrivateCollections = () =>
  api.get("/collections/list_collections");
export const createPrivateCollection = (data) =>
  api.post("/collections/create", data);
export const deletePrivateCollection = (collectionId) =>
  api.delete("/collections/delete_collection", {
    params: { collection_id: collectionId },
  });
export const getPublicCollections = () =>
  api.get("/collections/public/collections");
export const createPublicCollection = (data) =>
  api.post("/collections/public/create_collection", data);
export const uploadDocument = (formData) =>
  api.post("/collections/upload", formData, { headers: formDataContentType });
export const processDocument = (data) => api.post("/collections/process", data);
export const uploadJsonData = (data) =>
  api.post("/collections/upload_json", data);
export const uploadPublicDocument = (formData) =>
  api.post("/collections/upload_public_document", formData, {
    headers: formDataContentType,
  });
export const deleteDocuments = (documentIds) =>
  api.delete("/collections/delete_documents", {
    data: { document_ids: documentIds },
    headers: jsonContentType,
  });
export const deletePublicDocument = (documentId) =>
  api.delete("/collections/delete_public_document", {
    params: { document_id: documentId },
  });
export const getDocumentsByCollection = (collectionId) =>
  api.post("/collections/list_private_documents", {
    collection_id: collectionId,
  });
export const listPublicDocuments = () =>
  api.get("/collections/list_public_document");
export const searchPublicDocuments = (query) =>
  api.get("/collections/search", { params: { query } });

// New API for updating collections
export const updateCollection = (payload) =>
  api.put("/collections/update", payload, { headers: jsonContentType });

// Bot Management APIs
export const getBotTokens = () => api.get("/bot/get_bot_tokens");
export const generateBotToken = (formData) =>
  api.post("/bot/generate_bot_token", formData, {
    headers: formDataContentType,
  });
export const generateEmbedCode = (payload) =>
  api.post("/bot/generate_embed_code", payload);
export const deleteEmbedCode = (botToken) =>
  api.delete("/bot/delete_embed_code", {
    data: { bot_token: botToken },
    headers: jsonContentType,
  });
export const fetchEmbedCode = (botToken) =>
  api.get("/bot/get_embed_code", { params: { bot_token: botToken } });
export const getBotTokenDetails = (bot_token) =>
  api.get("/bot/get_bot_token_details", { params: { bot_token } });
export const updateBotToken = (formData) =>
  api.put("/bot/update_bot_token", formData, { headers: formDataContentType });
export const deleteBotToken = (botToken) =>
  api.delete("/bot/delete_bot_token", {
    data: { bot_token: botToken },
    headers: jsonContentType,
  });

// Function to get bot details via bot token and JWT token
export const getBotTokenDetailsViaHeader = async (botToken) => {
  const jwtToken = localStorage.getItem("token"); // Retrieve JWT token from localStorage

  return api.get("/bot_management/fetch_bot_details_by_token", {
    headers: {
      Authorization: `${botToken}`,
      "JWT-Authorization": jwtToken ? `Bearer ${jwtToken}` : undefined,
    },
  });
};

// Function to fetch quiz details without bot_token
export const fetchQuizDetails = async (quizId) => {
  const response = await axios.get(`${API_BASE_URL}/bot_management/get_quiz`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Include JWT token
    },
    params: {
      quiz_id: quizId,
    },
  });
  return response;
};

// Function to submit quiz answers without bot_token
// src/apiService.js

export const submitQuizAnswers = async (quizId, attemptId) => {
  return api.post(
    "/bot_management/submit_quiz",
    {
      quiz_id: quizId,
      attempt_id: attemptId,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }
  );
};

// Function to search with bot token and JWT token
export const searchWithBotToken = (data) => {
  const payload = {
    query: data.query,
    conversation_id: data.conversation_id || null,
    // token: data.token || null, // Remove if not needed by backend
  };

  console.log("Payload in searchWithBotToken:", payload);

  const jwtToken = localStorage.getItem("token"); // Retrieve JWT token from localStorage

  return api.post("/bot/search_with_bot_token", payload, {
    headers: {
      Authorization: `${data.bot_token}`, // Bot Token
      "JWT-Authorization": jwtToken ? `Bearer ${jwtToken}` : undefined, // JWT Token
      "Content-Type": "application/json",
    },
  });
};

// Conversation APIs
export const getAllConversations = () =>
  api.get("/conversations/get_all_conversations");
export const getConversation = (conversation_id) =>
  api.get("/conversations/get_conversation", { params: { conversation_id } });
export const getConversationsByCollection = (collection_id) =>
  api.get("/conversations/get_conversations_by_collection", {
    params: { collection_id },
  });
export const shareCollectionWithUser = (collectionId, sharedUser, role) =>
  api.post("/collections/share", {
    collection_id: collectionId,
    user: sharedUser,
    role,
  });
export const searchWithConversation = (payload) =>
  api.post("/conversations/search_with_conversation", payload);
export const deleteConversation = (conversation_id) =>
  api.delete("/conversations/delete_conversation", {
    params: { conversation_id },
  });
export const getSharedUsers = (botToken) =>
  api.get("/bot/view_shared_users", { params: { bot_token: botToken } });
export const removeSharedUser = (botToken, sharedUser) =>
  api.post("/bot/remove_shared_user", {
    bot_token: botToken,
    user: sharedUser,
  });
export const shareBot = (payload) =>
  api.post("/bot/share_bot", payload, { headers: jsonContentType });
export const downloadDocument = (documentId) =>
  api.get(`/collections/download/${documentId}`, { responseType: "blob" });

// Function to get all quizzes
export const getAllQuizzes = () => api.get("/bot_management/quizzes");

// Function to get all quiz results
export const getQuizResults = () => api.get("/bot_management/quizzes/results");

// Function to get performance data
export const getPerformanceData = () =>
  api.get("/bot_management/quizzes/performance");

// Function to get dashboard summary
export const getDashboardSummary = () => api.get("/bot_management/dashboard");

// Function to fetch progress data
export const getProgressData = () =>
  api.get("/bot_management/dashboard/progress");

// Function to fetch recommendations
export const getRecommendations = () =>
  api.get("/bot_management/dashboard/recommendations");
// src/apiService.js

// Change this function name from fetchQuizDetails to getQuizDetails
export const getQuizDetails = async (quizId) => {
  const response = await axios.get(`${API_BASE_URL}/bot_management/get_quiz`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Include JWT token
    },
    params: {
      quiz_id: quizId,
    },
  });
  return response;
};
// Function to delete a quiz
export const deleteQuiz = (quizId) =>
  api.delete(`/bot_management/quiz/${quizId}`);

// Function to update a quiz
export const updateQuiz = (quizId, data) =>
  api.put(`/bot_management/quiz/${quizId}`, data);

// Function to get quiz attempts
export const getQuizAttempts = (quizId) =>
  api.get(`/bot_management/quiz/${quizId}/attempts`);

// Function to get quizzes grouped by category
export const getQuizzesByCategory = () =>
  api.get("/bot_management/quizzes/grouped");

// Function to delete a quiz attempt
export const deleteQuizAttempt = (quizId, attemptId) =>
  api.delete(`/bot_management/quiz/${quizId}/attempts/${attemptId}`);

// Function to get quiz categories
export const getQuizCategories = () =>
  api.get("/bot_management/quizzes/categories");

// Function to save quiz progress
export const saveQuizProgress = (
  quizId,
  attemptId,
  questionId,
  selectedOption
) => {
  const jwtToken = localStorage.getItem("token"); // Retrieve JWT token from localStorage

  return api.post(
    "/bot_management/save_quiz_progress",
    {
      quiz_id: quizId,
      attempt_id: attemptId,
      question_id: questionId,
      selected_option: selectedOption,
    },
    {
      headers: {
        Authorization: jwtToken ? `Bearer ${jwtToken}` : undefined,
        "Content-Type": "application/json",
      },
    }
  );
};
// Function to start a quiz and get an attempt_id
export const startQuiz = (quizId, attemptId = null) => {
  const jwtToken = localStorage.getItem("token"); // Retrieve JWT token from localStorage

  const payload = { quiz_id: quizId }; // Base payload with quiz_id
  if (attemptId) {
    payload.attempt_id = attemptId; // Add attempt_id if provided
  }

  return api.post("/bot_management/start_quiz", payload, {
    headers: {
      Authorization: jwtToken ? `Bearer ${jwtToken}` : undefined,
      "Content-Type": "application/json",
    },
  });
};
// Function to get all conversations for a bot
export const getAllBotConversations = async (botToken) => {
  const jwtToken = localStorage.getItem("token"); // Retrieve JWT token from localStorage

  return api.get("/bot_management/get_all_conversations", {
    headers: {
      Authorization: `${botToken}`, // Bot Token
      "JWT-Authorization": jwtToken ? `Bearer ${jwtToken}` : undefined, // JWT Token
    },
  });
};

// Function to get a specific conversation for a bot
export const getBotConversation = async (botToken, conversationId) => {
  const jwtToken = localStorage.getItem("token"); // Retrieve JWT token from localStorage

  return api.get("/bot_management/get_bot_conversation", {
    headers: {
      Authorization: `${botToken}`,
      "JWT-Authorization": jwtToken ? `Bearer ${jwtToken}` : undefined,
    },
    params: { conversation_id: conversationId },
  });
};
